// Package
import React from 'react';
import ReactModal from 'react-responsive-modal';

import appstore from '../../assets/img/button_appstore.png';
import downloadapk from '../../assets/img/button_downloadapk.png';
import googleplay from '../../assets/img/button_googleplay.png';

export default class Download extends React.Component {

	render(){
		return(
			<div>
				<ReactModal 
					open={this.props.modalIsOpen} 
					onClose={this.props.onClose} 
					closeOnEsc={false}
	      			closeOnOverlayClick={false}
	      			center
					classNames={{ overlay: 'custom-overlay', modal: 'custom-modal', closeButton:'custom-close' }}
	      		>
					<div id="download">
						<div className="title_down">Download AOV</div>
						<div className="pilih">
							<p>Pilih caramu untuk mengunduh</p>
							<p>Arena of Valor Indonesia</p>
						</div>
						<div className="btn_download">
							<div className="box_download">
								<a href="https://app.appsflyer.com/com.garena.game.kgid?pid=OrganicA&c=mainpage_AND" target="_blank" rel="noopener noreferrer">
									<div className="playstore">
										<img src={googleplay} alt="" className="img-fluid"/>
									</div>
								</a>
							</div>
							<div className="box_download">
								<a href="https://app.appsflyer.com/id1222857607?pid=OrganicA&c=mainpage_IOS" target="_blank" rel="noopener noreferrer">
									<div className="appstore">
										<img src={appstore} alt="" className="img-fluid"/>
									</div>
								</a>
							</div>
							<div className="box_download hide">
								<a href="https://app.appsflyer.com/com.garena.game.kgid-Standalone?pid=OrganicA&c=apk_regularsize_2612&af_r=https://dlmobilegarena-a.akamaihd.net/kgid/apk/GarenaAOV.apk" target="_blank" rel="noopener noreferrer">
									<div className="small"><img src={downloadapk} alt="" className="img-fluid"/></div>
								</a>
							</div>
						</div>
					</div>
				</ReactModal>
			</div>
		);
	}
}
