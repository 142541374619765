import React from 'react';
import { connect } from 'react-redux';

import Sponsor from './Sponsor';
class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mainBG: '',
		};
	}

	componentDidMount() {
		const handleBackground = () => {
			if (this.props.assets) {
				if (window.innerWidth > 767) {
					this.setState({
						mainBG: this.props.assets.main_bg,
					});
				} else {
					this.setState({
						mainBG: this.props.assets.main_bg_mobile,
					});
				}
			}
		};
		window.addEventListener('resize', handleBackground);
	}

	render() {
		let initialBG = '';
		if (window.innerWidth > 767) {
			initialBG = this.props.assets.main_bg;
		} else {
			initialBG = this.props.assets.main_bg_mobile;
		}

		return (
			<div
				className="sec1"
				style={{
					background: `url("${this.state.mainBG || initialBG}") top/cover no-repeat `,
				}}
			>
				<div className="container">
					<div className={`landing ${this.props.assets.featured_sponsor ? 'landing-sponsored' : ''} `}>
						<img src={this.props.assets.banner_logo} alt="" className="img-fluid logoaov" />
						<div className="headline">
							<img src={this.props.assets.asl_desc} alt="" className="img-fluid d-md-block d-none" />
							<img src={this.props.assets.asl_desc_mobile} alt="" className="img-fluid d-md-none d-block" />
						</div>
						<div className="sub-headline landing-with-sponsor">
							<a
								href="https://www.facebook.com/aovesportsid"
								className="btn_click"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="buttons">Dukung Tim Favoritmu</div>
							</a>
							<div className="sponsor-container">
								<a
									href={this.props.assets.featured_sponsor_link || null}
									target="_blank"
									rel="noopener noreferrer"
									className="featured-sponsor"
								>
									<img src={this.props.assets.featured_sponsor || null} alt="" className="img-fluid" />
								</a>
								<a
									href={this.props.assets.featured_sponsor_link_2 || null}
									target="_blank"
									rel="noopener noreferrer"
									className="featured-sponsor"
								>
									<img src={this.props.assets.featured_sponsor_2 || null} alt="" className="img-fluid" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<Sponsor />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	assets: state.data.assets,
});

export default connect(mapStateToProps)(Landing);
