import { api } from '../../template/partial/config';

let axios = require('axios');
axios.defaults.withCredentials = true;

var schedule = api + 'get_schedule';
var teams = api + 'get_teams';
var lastmatch = api + 'get_lastmatch';
var standings = api + 'get_standing';
var video = api + 'get_video';
var news = api + 'get_news';
var newsbyid = api + 'get_news_by_id';
var active_match = api + 'get_activematch';

export const getSchedule = () => {
  return dispatch => {
    return axios
      .get(schedule)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_SCHEDULE',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};

export const getActive = () => {
  return dispatch => {
    return axios
      .get(active_match)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_ACTIVE',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};

export const getLastWeek = () => {
  return dispatch => {
    return axios
      .get(lastmatch)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_LASTWEEK',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};

export const getTeams = () => {
  return dispatch => {
    return axios
      .get(teams)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_TEAMS',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};

export const getStandings = () => {
  return dispatch => {
    return axios
      .get(standings)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_STANDINGS',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};

export const getVideo = () => {
  return dispatch => {
    return axios
      .get(video)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_VIDEO',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};

export const getNews = () => {
  return dispatch => {
    return axios
      .get(news)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_NEWS',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};

export const updateLastPart = data => {
  return {
    type: 'UPDATE_LAST_PART',
    data
  };
};

export const getNewsbyId = id => {
  return dispatch => {
    return axios
      .get(newsbyid + '/' + id)
      .then(response => {
        var data = response.data.data;
        dispatch({
          type: 'FETCH_DATA_NEWSBYID',
          data
        });
      })
      .catch(error => {
        var data = 'Please refresh your browser';
        dispatch({
          type: 'FETCH_DATA_ERROR',
          data
        });
      });
  };
};
