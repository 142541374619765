import React from "react";
import { connect } from "react-redux";

class SectionTitle extends React.Component {
  render() {
    let sectionBG = this.props.assets.section_bg;

    return (
      <div className="title" style={{ backgroundImage: `url(${sectionBG})` }}>
        <div className="container">
          <span>{this.props.title}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assets: state.data.assets,
});

export default connect(mapStateToProps)(SectionTitle);
