import React, { Component } from 'react';
import {
	Switch,
	BrowserRouter as Router,
	Route,
	// Redirect,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';

// PAGES
import Home from './pages/Home';
import Teams from './pages/Teams';
import Schedule from './pages/Schedule';
import Standings from './pages/Standings';
import News from './pages/News';
// import NewsDetail from './pages/NewsDetail'
import Mechanism from './pages/Mechanism';

// PARTIAL
import NoMatch from './partial/NoMatch';
import Navbar from './partial/Navbar';
import Footer from './partial/Footer';

import { get_translate } from '../redux/actions/main';

ReactGA.initialize([
	{
		trackingId: process.env.REACT_APP_GA,
	},
]);

const $ = window.$;

class Main extends Component {
	componentDidMount() {
		var header = $('#garena_top_bg02');

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= header.height()) {
				$('.navbar').css('top', '0');
			} else {
				$('.navbar').css('top', 'initial');
			}
		});

		this.props.dispatch(get_translate());
	}

	render() {
		const {use_teams,use_standings} = this.props?.assets;
		return (
			<Router>
				<div className="wrapper">
					<div className="w_pop">
						<div className="content">
							<div className="w_pop_img"></div>
							<p className="w_tips">
								Silahkan buka di smartphone kamu atau putar smartphone kamu ke posisi vertical untuk visual yang lebih
								baik.
							</p>
						</div>
					</div>
					<a className="floating_main" href="https://aov.garena.co.id/main/" target="_blank" rel="noopener noreferrer">
						<div id="floating_hover"></div>
					</a>
					<Navbar />
					<div className="content">
						<Switch>
							<Route exact path="/" component={Home} />
							<Route exact path="/home" component={Home} />
							<Route exact path="/schedule" component={Schedule} />
							<Route exact path="/mechanism" component={Mechanism} />
							<Route exact path="/news/" component={News} />
							{use_teams && <Route exact path="/teams" component={Teams} />}
							{use_standings && <Route exact path="/standings" component={Standings} />}
							<Route component={(location) => <NoMatch location={location.location.pathname} />} />
						</Switch>
					</div>
					<Footer />
				</div>
			</Router>
		);
	}
}

const mapStateToProps = (state) => ({
	assets: state.data.assets,
});

export default connect(mapStateToProps)(Main);
