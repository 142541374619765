import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class CheckTeams extends React.Component {
  render() {
    return (
      <div className="sec5 container">
        <div className="team_headline">
          <div
            className="box"
            data-aos="fade-right"
            data-aos-easing="ease"
            data-aos-delay="100"
          >
            <div className="title">Meet The Superstars</div>
            <p></p>
            <Link to="/teams" className="btn_click">
              <div className="buttons">Check Teams</div>
            </Link>
          </div>
          <div
            className="box"
            data-aos="fade-left"
            data-aos-easing="ease"
            data-aos-delay="100"
          >
            <img
              src={this.props.assets.team_superstars}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assets: state.data.assets,
});

export default connect(mapStateToProps)(CheckTeams);
