import React from 'react';

export default class NoMatch extends React.Component {
    render() {
        return (
            <div id="noMatch">
                <p>
                    Maaf, Halaman {this.props.location} yang anda cari tidak ditemukan
                </p>
            </div>
        );
    }
}