import React from "react";
import { connect } from "react-redux";

class Video extends React.Component {
  render() {
    var videos = [];
    if (this.props.videos) {
      videos = this.props.videos;
    }

    return (
      <div className={"sec6 "}>
        <div className="video">
          <div className="container">
            <div className="head_title">
              <p>video</p>
            </div>
          </div>
          <div className="box-video">
            {videos.length
              ? videos.map((video, idx) => (
                  <div className="container" key={idx}>
                    <div className="box vid">
                      <iframe
                        width="100%"
                        height="100%"
                        src={video.youtube_url}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="yt"
                      ></iframe>
                    </div>
                    <div
                      className="box text"
                      dangerouslySetInnerHTML={{ __html: video.description }}
                    ></div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  videos: state.data.videos,
  assets: state.data.assets,
});

export default connect(mapStateToProps)(Video);
