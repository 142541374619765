import React from "react";
import { connect } from "react-redux";

import timi from "../../assets/img/timi.png";
import logo from "../../assets/img/logo_m.png";
import Sponsor from "../component/Sponsor";

class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <Sponsor />
        <div className="container">
          <div className="top_footer">
            <p>follow us</p>
            <div className="socmed">
              <a
                href="https://web.facebook.com/aovesportsid/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-socmed fb"></div>
              </a>
              <a
                href="https://www.instagram.com/garenaaovid/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-socmed ig"></div>
              </a>
              <a
                href="https://www.youtube.com/channel/UCBVBGNrag1o-V08vwYzoIwg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-socmed yt"></div>
              </a>
            </div>
          </div>
          <div className="bottom_footer">
            <div className="img-footer">
              <img src={timi} alt="timi" />
              <img src={logo} alt="logo" />
            </div>
            <div className="c-text">
              Copyright © Garena Online. Trademarks belong to their respective
              owners. All Rights Reserved
              <br />
              Copyright Ⓒ Tencent. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assets: state.data.assets,
});

export default connect(mapStateToProps)(Footer);
