import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import Pagination from '../component/Pagination';
import { Helmet } from 'react-helmet';

let actions = require('../../redux/actions');
const $ = window.$;

class News extends React.Component {
	componentDidMount() {
		ReactGA.send({ hitType: 'pageview', page: '/news' });
		var lastPart = window.location.href.split('/');
		lastPart = lastPart[lastPart.length - 1];
		this.props.dispatch(actions.updateLastPart(lastPart));

		var banner = $('#news .title').height();

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= banner) {
				$('.navbar').css('background', '#000');
			} else {
				$('.navbar').css('background', '#000');
			}
		});
	}

	onChangePage = (pageOfItems = []) => {
		var data = pageOfItems;

		this.props.dispatch({
			type: 'SET_PAGEOFITEMS',
			data,
		});
	};

	// formatDate = (date) => {
	//     var month = ["01","02","03","04","05","06","07","08","09","10","11","12"];
	//     var new_date = new Date(date);
	//     var format = new_date.getDate() + '/' + (month[new_date.getMonth()]) + '/' + new_date.getFullYear();
	//     return format;
	// }

	createMarkup = (body) => {
		return { __html: body };
	};

	TrackNews = (title) => {
		ReactGA.event({
			category: 'News',
			action: title,
		});
	};

	render() {
		var news = '';
		var pageOfItems = '';
		if (this.props.news) {
			news = this.props.news;
		}

		if (this.props.pageOfItems) {
			pageOfItems = this.props.pageOfItems;
		}

		return (
			<div id="news" className="bg">
				<Helmet>
					<meta charSet="utf-8" />
					<title>ASL - News</title>
					<meta
						name="description"
						content="AOV Star League, the most premium mobile Esports league hosted by Garena Indonesia, which is participated by EVOS, GGWP.ID, Rex Regum Qeon, Saudara Esports, Bigetron, Dunia Games, and Headhunters"
					/>
				</Helmet>
				<div className="title">
					<div className="container">
						<span>News</span>
					</div>
				</div>
				<div className="container detail">
					<div className="header"></div>
					<div className="news_list">
						{pageOfItems ? (
							pageOfItems.map((data, x) => (
								<a
									key={x}
									className="box_news"
									href={data.link}
									target="_blank"
									rel="noopener noreferrer"
									onClick={() => this.TrackNews(data.news_title)}
								>
									<div className="box">
										<img src={data.news_image} alt="" className="img-fluid" />
									</div>
									<div className="box details">
										<div className="head">{data.news_title}</div>
										<div className="by">
											<p>By : {data.media ? data.media : '-'}</p>
											<p>{data.news_date}</p>
										</div>
										<div className="highlight" dangerouslySetInnerHTML={this.createMarkup(data.headline)}></div>
									</div>
								</a>
							))
						) : (
							<p>Tidak ada berita</p>
						)}
						<Pagination items={news} onChangePage={this.onChangePage} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	news: state.data.news,
	pageOfItems: state.data.pageOfItems,
});

export default connect(mapStateToProps)(News);
