import ReactGA from 'react-ga4';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SectionTitle from '../component/SectionTitle';

let actions = require('../../redux/actions');
const $ = window.$;

class Standings extends React.Component {
	componentDidMount() {
		ReactGA.send({ hitType: 'pageview', page: '/standings' });

		var lastPart = window.location.href.split('/');
		lastPart = lastPart[lastPart.length - 1];
		this.props.dispatch(actions.updateLastPart(lastPart));

		var banner = $('#standings .title').height();

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= banner) {
				$('.navbar').css('background', '#000');
			} else {
				$('.navbar').css('background', '#000');
			}
		});
	}

	render() {
		var standings = '';
		if (this.props.standings) {
			standings = this.props.standings;
		}
		return (
			<div id="standings" className="bg">
				<Helmet>
					<meta charSet="utf-8" />
					<title>ASL - Standings</title>
					<meta
						name="description"
						content="AOV Star League, the most premium mobile Esports league hosted by Garena Indonesia, which is participated by EVOS, GGWP.ID, Rex Regum Qeon, Saudara Esports, Bigetron, Dunia Games, and Headhunters"
					/>
				</Helmet>
				<SectionTitle title="Standings" />
				<div className="container detail">
					<div className="header"></div>
					<div className="standings-wrapper">
						<table className="table ">
							<thead>
								<tr>
									<th>Rank</th>
									<th className="team_name">Team</th>
									<th className="d-md-table-cell d-none">Match</th>
									<th className="d-md-none">M</th>
									<th className="d-md-table-cell d-none">Game Win</th>
									<th className="d-md-none">W</th>
									<th className="d-md-table-cell d-none">Game Lose</th>
									<th className="d-md-none">L</th>
									<th className="d-md-table-cell d-none">Points</th>
									<th className="d-md-none">P</th>
								</tr>
							</thead>
							<tbody>
								{standings
									? standings.map((data, x) => (
											<tr key={x}>
												<td className="table-data">{data.rank}</td>
												<td className="team_name">
													<img src={data.team.team_logo} alt="" className="img-fluid" />
													<span className="team_name__name">{data.team.team_name}</span>
												</td>
												<td className="table-data">{data.match}</td>
												<td className="table-data">{data.win}</td>
												<td className="table-data">{data.lose}</td>
												<td className="table-data">{data.point}</td>
											</tr>
									  ))
									: ''}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	lastPart: state.data.lastPart,
	standings: state.data.standings,
});

export default connect(mapStateToProps)(Standings);
