import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SectionTitle from '../component/SectionTitle';

let actions = require('../../redux/actions');
const $ = window.$;
const AOS = window.AOS;

class Teams extends React.Component {
	componentDidMount() {
		ReactGA.send({ hitType: 'pageview', page: '/teams' });

		AOS.init({
			easing: 'ease-in-out-sine',
		});
		var lastPart = window.location.href.split('/');
		lastPart = lastPart[lastPart.length - 1];
		this.props.dispatch(actions.updateLastPart(lastPart));

		var banner = $('#teams .title').height();

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= banner) {
				$('.navbar').css('background', '#000');
			} else {
				$('.navbar').css('background', '#000');
			}
		});
	}

	render() {
		var list = this.props.teams;

		return (
			<div id="teams" className="bg">
				<Helmet>
					<meta charSet="utf-8" />
					<title>ASL - Teams</title>
					<meta
						name="description"
						content="AOV Star League, the most premium mobile Esports league hosted by Garena Indonesia, which is participated by EVOS, GGWP.ID, Rex Regum Qeon, Saudara Esports, Bigetron, Dunia Games, and Headhunters"
					/>
				</Helmet>
				<SectionTitle title="Teams" />
				<div className="detail container">
					<div className="header"></div>
					<div className="teams-logo" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="20">
						<ul className="nav" role="tablist">
							{list
								? list.map((data, x) => (
										<li key={x}>
											<a href={'#team' + x} data-toggle="tab" role="tab" className={x === 0 ? 'active' : ''}>
												<img src={data.team_logo} alt="" className={'img-fluid ' + data.team_logo} />
												<div className="overlay_teams"></div>
											</a>
										</li>
								  ))
								: ''}
						</ul>
					</div>

					<div className="header-detail"></div>
					<div className="team-detail">
						<div className="tab-content">
							{list
								? list.map((data, x) => (
										<div
											key={x}
											className={'tab-pane team-player' + (x === 0 ? ' show active' : '')}
											id={'team' + x}
											role="tabpanel"
										>
											<div className="team-name">
												<img src={data.team_logo || ''} alt="logo" />
												<p>{data.team_name || ''}</p>
											</div>
											<ul>
												{data.player
													? data.player.map((item, i) => (
															<li key={i}>
																<img src={item.photo} alt="icon" className="player-picture icon" />
																<div className="player-info">
																	<div className="player-info__container">
																		<span className="team">{decodeURI(item.ingame || '')}</span>
																		<span className="role">{item.role || ''}</span>
																	</div>
																	<span className="name">{decodeURI(item.fullname || '')}</span>
																	<span className="role mobile">{item.role || ''}</span>
																</div>
															</li>
													  ))
													: ''}
											</ul>
										</div>
								  ))
								: ''}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	teams: state.data.teams,
});

export default connect(mapStateToProps)(Teams);
