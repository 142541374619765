import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Download from "./Download";

let actions = require("../../redux/actions");

class Navbar extends React.Component {
  componentDidMount() {
    var lastPart = window.location.href.split("/");
    lastPart = lastPart[lastPart.length - 1];
    this.props.dispatch(actions.updateLastPart(lastPart));
  }

  handleClickMenu = () => {
    this.props.dispatch({ type: "OPEN_MENU" });
  };

  openMenu = () => {
    this.props.dispatch({ type: "OPEN_MENU" });
  };

  openDownload = () => {
    this.props.dispatch({ type: "OPEN_DOWNLOAD" });
  };

  closeDownload = () => {
    this.props.dispatch({ type: "CLOSE_DOWNLOAD" });
  };

  render() {
    var lastPart = this.props.lastPart;
    const {use_standings, use_teams} = this.props?.assets;
    
    return (
      <div id="navbar" className="navbar">
        <div
          className={
            "nav-cont animated " +
            lastPart +
            (this.props.menuOpen ? " active" : "")
          }
        >
          <div
            className={
              "hamburger hamburger--spin " +
              (this.props.menuOpen ? "is-active" : "")
            }
            onClick={this.openMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </div>
          <div className="download d-none">
            <div onClick={this.openDownload}>Download AOV</div>
          </div>
          <div className="nav-menu">
            <ul>
              <li className="logo_asl">
                <img
                  src={this.props.assets.header_logo}
                  alt=""
                  className="img-fluid"
                />
              </li>
              <Link to="/home">
                <li className={lastPart === "home" ? "active" : ""}>Home</li>
              </Link>
              <Link to="/schedule">
                <li className={lastPart === "schedule" ? "active" : ""}>
                  Schedule
                </li>
              </Link>
              {use_standings &&
                <Link to="/standings">
                  <li className={lastPart === "standings" ? "active" : ""}>
                    Standings
                  </li>
                </Link>
              }
              {use_teams &&
                <Link to="/teams">
                  <li className={lastPart === "teams" ? "active" : ""}>Teams</li>
                </Link>
                }
              <Link to="/mechanism">
                <li className={lastPart === "mechanism" ? "active" : ""}>
                  {this.props.assets.asl_header_title}
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div className={"menu " + (this.props.menuOpen ? "active" : "")}>
          <img
            src={this.props.assets.header_logo_mobile}
            alt=""
            className="logo"
          />
          <ul>
            <Link to="/home" onClick={this.handleClickMenu}>
              <li>Home</li>
            </Link>
            <Link to="/schedule" onClick={this.handleClickMenu}>
              <li>Schedule</li>
            </Link>
            {use_standings &&
            <Link to="/standings" onClick={this.handleClickMenu}>
              <li>Standings</li>
            </Link>}
            {use_teams &&
            <Link to="/teams" onClick={this.handleClickMenu}>
              <li>Teams</li>
            </Link>}
            <Link to="/mechanism" onClick={this.handleClickMenu}>
              <li>{this.props.assets.asl_header_title}</li>
            </Link>
          </ul>
        </div>
        <Download
          modalIsOpen={this.props.modalIsOpen}
          onClose={this.closeDownload}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menuOpen: state.data.menuOpen,
  modalIsOpen: state.data.DownloadIsOpen,
  lastPart: state.data.lastPart,
  assets: state.data.assets,
});

export default connect(mapStateToProps)(Navbar);
