const initialState = {
  menuOpen: false,
  DownloadIsOpen: false,
  error: "",
  lastPart: "",
  schedule: "",
  teams: "",
  standings: "",
  lastweek: "",
  video: "QfE3O9Zt7eY",
  videos: [],
  news: "",
  newstab: [
    { name: "all", label: "" },
    { name: "update", label: "1" },
    { name: "event", label: "2" },
  ],
  newsbyid: "",
  pageOfItems: [],
  link_yt: "http://aov.co.id/aslselasa",
  assets: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "OPEN_MENU":
      return (state = {
        ...state,
        menuOpen: !state.menuOpen,
      });
    case "CLOSE_MENU":
      return (state = {
        ...state,
        menuOpen: false,
      });
    case "OPEN_DOWNLOAD":
      return (state = {
        ...state,
        DownloadIsOpen: true,
      });
    case "CLOSE_DOWNLOAD":
      return (state = {
        ...state,
        DownloadIsOpen: false,
      });
    case "UPDATE_LAST_PART":
      return (state = {
        ...state,
        lastPart: action.data,
      });
    case "FETCH_DATA_SCHEDULE":
      return (state = {
        ...state,
        schedule: action.data,
      });
    case "FETCH_DATA_TEAMS":
      return (state = {
        ...state,
        teams: action.data,
      });
    case "FETCH_DATA_STANDINGS":
      return (state = {
        ...state,
        standings: action.data,
      });
    case "FETCH_DATA_LASTWEEK":
      return (state = {
        ...state,
        lastweek: action.data,
      });
    case "FETCH_DATA_VIDEO":
      return (state = {
        ...state,
        video: action.data.youtube_id,
      });
    case "FETCH_DATA_NEWS":
      return (state = {
        ...state,
        news: action.data,
      });
    case "FETCH_DATA_VIDEOS":
      return (state = {
        ...state,
        videos: action.data,
      });
    case "FETCH_DATA_NEWSBYID":
      return (state = {
        ...state,
        newsbyid: action.data,
      });
    case "SET_PAGEOFITEMS":
      return (state = {
        ...state,
        pageOfItems: action.data,
      });
    case "FETCH_DATA_ACTIVE":
      return (state = {
        ...state,
        link_yt: action.data.link_yt,
      });
    case "FETCH_DATA_ASSETS":
      return (state = {
        ...state,
        assets: action.data,
      });
    default:
      return state;
  }
};
