import React from "react";
import { connect } from "react-redux";

const $ = window.$;

class Schedule extends React.Component {
  // componentDidMount(){
  // 	var $elem=$('.handle_nav .nav-tabs');
  //        var newScrollLeft = $elem.scrollLeft(),
  //            width=$elem.width(),
  //            scrollWidth=$elem.get(0).scrollWidth;

  //            console.log(newScrollLeft);

  //        if(scrollWidth >= (newScrollLeft-width)){
  //        	$('.next-btn').removeClass('d-none');
  //            $('.prev-btn').removeClass('d-none');

  //        }

  //        if (scrollWidth <= (newScrollLeft+width)) {
  //            $('.next-btn').addClass('d-none');
  //            $('.prev-btn').removeClass('d-none');
  //            console.log('left');

  //        }

  //        if (newScrollLeft === 0) {
  //            $('.next-btn').removeClass('d-none');
  //            $('.prev-btn').addClass('d-none');
  //            console.log('right');

  //        }
  // }

  viewTime = (time) => {
    var times = time.split(":");
    times = times[0] + " : " + times[1];
    return times;
  };

  ordinal_suffix_of = (ii) => {
    var i = new Date(ii);
    i = this.formatDate(i).split(" ");
    i = i[i.length - 1];

    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  };

  formatDate = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var monthNames = [
      "Jan",
      "Feb",
      "March",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var string = date.getDay();
    var day = date.getDate();
    var monthIndex = date.getMonth();
    return days[string] + " " + monthNames[monthIndex] + " " + day;
  };

  viewDate = (datedate) => {
    var date = new Date(datedate);
    date = this.formatDate(date).split(" ");
    return date[1] + " " + date[2];
  };

  viewDay = (date) => {
    var day = new Date(date);
    day = this.formatDate(day).split(" ");
    return day[0];
  };
  
  componentDidUpdate(prevProps) {
    if (prevProps?.schedule !== this.props.schedule) {
      this.setState({
        selected: this.props.schedule[0] ?? {},
      });
    }
    // var scrollLeftPrev = 0;
    $(".handle_nav .nav-tabs").scroll(function () {
      var $elem = $(".handle_nav .nav-tabs");
      var newScrollLeft = $elem.scrollLeft(),
      width = $elem.width(),
      scrollWidth = $elem.get(0).scrollWidth;
      // console.log(newScrollLeft);
      
      if (scrollWidth >= newScrollLeft - width) {
        $(".next-btn").removeClass("d-none");
        $(".prev-btn").removeClass("d-none");
        // console.log('center')
      }
      
      if (scrollWidth <= newScrollLeft + width) {
        $(".next-btn").addClass("d-none");
        $(".prev-btn").removeClass("d-none");
        // console.log('left')
      }
      if (newScrollLeft === 0) {
        $(".next-btn").removeClass("d-none");
        $(".prev-btn").addClass("d-none");
        // console.log('right')
      }

      // scrollLeftPrev = newScrollLeft;
    });
  }

  handleScroll = (trigger) => {
    var far = $(".handle_nav .nav-tabs").width();
    var next = $(".handle_nav .nav-tabs").scrollLeft() + far;
    var prev = $(".handle_nav .nav-tabs").scrollLeft() - far;
    var scroll;

    if (trigger === "next") {
      scroll = next;
    } else if (trigger === "prev") {
      scroll = prev;
    }

    $(".handle_nav .nav-tabs").animate({ scrollLeft: scroll }, "slow");
  };

  constructor(props) {
    super(props);
    this.state = { selected: props.schedule[0] ?? {} };
  }


  render() {
    var lastweek = "";
    var list = this.props.schedule;
    if (this.props.lastweek) {
      lastweek = parseInt(this.props.lastweek[0].schedule, 10);
    }

    return (
      <div
        className="box_schedule"
        data-aos="fade-up"
        data-aos-easing="ease"
        data-aos-delay="30"
      >
        <div className="handle_nav">
          <div
            className="handle prev-btn"
            onClick={() => this.handleScroll("prev")}
          ></div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {list
              ? list.map((data, x) => (
                  <li
                    key={x}
                    className="nav-item"
                    onClick={() => {
                      this.setState({
                        selected: data,
                      });
                    }}
                  >
                    <a
                      className={
                        "nav-link " +
                        (lastweek
                          ? x === lastweek
                            ? "active"
                            : ""
                          : x === 0
                          ? "active"
                          : "")
                      }
                      id={"week" + data.schedule + "-tab"}
                      data-toggle="tab"
                      href={"#week" + data.schedule}
                      role="tab"
                      aria-selected={x === 0 ? "true" : ""}
                    >
                      {this.viewDate(data.date) +
                        this.ordinal_suffix_of(data.date)}
                    </a>
                  </li>
                ))
              : ""}
          </ul>
          <div
            className="handle next-btn"
            onClick={() => this.handleScroll("next")}
          ></div>
        </div>
        <div className="tab-content" id="myTabContent">
          {list
            ? list.map((data, x) => (
                <div
                  key={x}
                  className={
                    "tab-pane fade" +
                    (lastweek
                      ? x === lastweek
                        ? "show active"
                        : ""
                      : x === 0
                      ? "show active"
                      : "")
                  }
                  id={"week" + data.schedule}
                  role="tabpanel"
                  aria-labelledby={"week" + data.schedule + "-tab"}
                >
                  <div className="detail_schedule">
                    <div className="time desktop">
                      <p>
                        <span>{this.viewDay(this.state.selected.date)}</span>{" "}
                        {this.viewDate(this.state.selected.date) +
                          this.ordinal_suffix_of(this.state.selected.date)}
                      </p>
                    </div>
                    <div className="detail">
                      {this.state.selected.match
                        ? this.state.selected.match.map((item, i) => (
                            <div key={i} className="box_detail">
                              <div className="time mobile">
                                <p>
                                  <span>{this.viewDay(data.date)}</span>{" "}
                                  {this.viewDate(data.date) +
                                    this.ordinal_suffix_of(data.date)}{" "}
                                  <span className="mobiletime">
                                    {item.time && this.viewTime(item.time)} WIB
                                  </span>
                                </p>
                              </div>
                              <div className="team">
                                <div
                                  className="logo"
                                  style={{
                                    background: `url("${item.home_team?.team_logo}") center/cover no-repeat `,
                                  }}
                                >
                                  <img
                                    src={item.home_team?.team_logo}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="name">
                                  {item.home_team?.team_name}
                                  {/* {item.home_team.team_name} */}
                                </div>
                                <div className="score">{item.home_score}</div>
                              </div>
                              <div className="vs">
                                <p>VS</p>
                                <p>
                                  {item.time && this.viewTime(item.time)} WIB
                                </p>
                              </div>
                              <div className="team away">
                                <div className="score">{item.away_score}</div>
                                <div className="name">
                                  {item.away_team?.team_name}
                                  {/* {item.away_team.team_name} */}
                                </div>
                                <div
                                  className="logo"
                                  style={{
                                    background: `url("${item.away_team?.team_logo}") center/cover no-repeat`,
                                  }}
                                >
                                  <img
                                    src={item.away_team?.team_logo}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  schedule: state.data.schedule,
  lastweek: state.data.lastweek,
});

export default connect(mapStateToProps)(Schedule);
