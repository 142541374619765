export var api = '';
export var base_url = '';

if (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '10.10.118.110'
) {
  api = 'https://esports.aov.garena.co.id/asl_api/index.php?/api/';
  base_url = 'http://localhost:3000/';
} else if (window.location.hostname === '10.10.12.13') {
  api = 'http://10.10.12.13/asl_api/api/';
  base_url = 'http://10.10.12.13/';
} else {
  api = 'https://esports.aov.garena.co.id/asl_api/api/';
  base_url = 'https://esports.aov.garena.co.id/';
}
