import React from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Landing from '../component/Landing';
import Schedule from '../component/Schedule';
import News from '../component/News';
import CheckTeams from '../component/CheckTeams';
import Video from '../component/Video';

let actions = require('../../redux/actions');
const AOS = window.AOS;
const $ = window.$;

class Home extends React.Component {
	triggerClick = (actions) => {
		var action = 'Click button ' + actions;

		ReactGA.event({
			category: 'Button',
			action: action,
		});
	};

	componentDidMount() {
		ReactGA.send({ hitType: 'pageview', page: '/home' });

		AOS.init({
			easing: 'ease-in-out-sine',
		});
		var lastPart = window.location.href.split('/');
		lastPart = lastPart[lastPart.length - 1];
		this.props.dispatch(actions.updateLastPart(lastPart));
		var $elem = $('.handle_nav .nav-tabs');
		var newScrollLeft = $elem.scrollLeft(),
			width = $elem.width(),
			scrollWidth = $elem.get(0).scrollWidth;

		if (scrollWidth <= newScrollLeft + width) {
			$('.next-btn').addClass('d-none');
			$('.prev-btn').removeClass('d-none');
		}
		if (newScrollLeft === 0) {
			$('.next-btn').removeClass('d-none');
			$('.prev-btn').addClass('d-none');
		}
	}

	componentDidUpdate() {
		// var scrollLeftPrev = 0;
		$('.handle_nav .nav-tabs').scroll(function () {
			var $elem = $('.handle_nav .nav-tabs');
			var newScrollLeft = $elem.scrollLeft(),
				width = $elem.width(),
				scrollWidth = $elem.get(0).scrollWidth;

			if (scrollWidth >= newScrollLeft - width) {
				$('.next-btn').removeClass('d-none');
				$('.prev-btn').removeClass('d-none');
			}

			if (scrollWidth <= newScrollLeft + width) {
				$('.next-btn').addClass('d-none');
				$('.prev-btn').removeClass('d-none');
			}
			if (newScrollLeft === 0) {
				$('.next-btn').removeClass('d-none');
				$('.prev-btn').addClass('d-none');
			}

			// scrollLeftPrev = newScrollLeft;
		});
	}

	render() {
		const {use_teams} = this.props?.assets;
		return (
			<div id="home">
				<Helmet>
					<meta charSet="utf-8" />
					<title>ASL - Home</title>
					<meta
						name="description"
						content="AOV Star League, the most premium mobile Esports league hosted by Garena Indonesia, which is participated by EVOS, GGWP.ID, Rex Regum Qeon, Saudara Esports, Bigetron, Dunia Games, and Headhunters"
					/>
				</Helmet>
				<Landing triggerClick={() => this.triggerClick('WATCH NOW 1')} link_yt={this.props.link_yt} />
				{/* <div className="sec2">
          <div
            className="container"
            data-aos="fade-left"
            data-aos-easing="ease"
            data-aos-delay="10"
          >
            <div className="info-reward">
              <p>1 Milyar Rupiah</p>
              <p>Total Prizepool</p>
            </div>
          </div>
        </div> */}
				<div className="sec3">
					<div className="schedule container">
						<div className="head_title" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="20">
							<p>schedule</p>
							<Link to="/schedule" className="check" onClick={() => this.triggerClick('DETAIL SCHEDULE')}>
								Cek Detail {'>'}{' '}
							</Link>
						</div>
						<Schedule />

						<div className="watch">
							<p>
								Tonton langsung Live streaming dari <span>YouTube Garena AOV</span> dan <span>Facebook Garena AOV</span>{' '}
							</p>
							<a href="http://aov.co.id/youtubeaov" className="btn_click" target="_blank" rel="noopener noreferrer">
								<div className="buttons">Watch Now</div>
							</a>
						</div>
					</div>
				</div>
				<News triggerClick={() => this.triggerClick('SEE NEWS')} />
				{use_teams &&
					<CheckTeams />
				}
				<Video />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	lastPart: state.data.lastPart,
	link_yt: state.data.link_yt,
	assets: state.data.assets
});

export default connect(mapStateToProps)(Home);
