import ReactGA from 'react-ga4';
import React from 'react';
import { connect } from 'react-redux';
import BoxSchedule from '../component/Schedule';
import { Helmet } from 'react-helmet';
import SectionTitle from '../component/SectionTitle';

let actions = require('../../redux/actions');
const AOS = window.AOS;
const $ = window.$;

class Schedule extends React.Component {
	componentDidMount() {
		ReactGA.send({ hitType: 'pageview', page: '/schedule' });

		AOS.init({
			easing: 'ease-in-out-sine',
		});
		var lastPart = window.location.href.split('/');
		lastPart = lastPart[lastPart.length - 1];
		this.props.dispatch(actions.updateLastPart(lastPart));

		var banner = $('#schedule .title').height();

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= banner) {
				$('.navbar').css('background', '#000');
			} else {
				// before transparan
				$('.navbar').css('background', '#000');
			}
		});
	}

	viewTime = (time) => {
		if (!time) {
			return time;
		}
		var times = time.split(':');
		times = times[0] + ' : ' + times[1];
		return times;
	};

	ordinal_suffix_of = (ii) => {
		var i = new Date(ii);
		i = this.formatDate(i).split(' ');
		i = i[i.length - 1];

		var j = i % 10,
			k = i % 100;
		if (j === 1 && k !== 11) {
			return 'st';
		}
		if (j === 2 && k !== 12) {
			return 'nd';
		}
		if (j === 3 && k !== 13) {
			return 'rd';
		}
		return 'th';
	};

	formatDate = (date) => {
		var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		var monthNames = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		var string = date.getDay();
		var day = date.getDate();
		var monthIndex = date.getMonth();
		return days[string] + ' ' + monthNames[monthIndex] + ' ' + day;
	};

	viewDate = (datedate) => {
		var date = new Date(datedate);
		date = this.formatDate(date).split(' ');
		return date[1] + ' ' + date[2];
	};

	viewDay = (date) => {
		var day = new Date(date);
		day = this.formatDate(day).split(' ');
		return day[0];
	};

	componentDidUpdate() {
		// var scrollLeftPrev = 0;
		$('.handle_nav .nav-tabs').scroll(function () {
			var $elem = $('.handle_nav .nav-tabs');
			var newScrollLeft = $elem.scrollLeft(),
				width = $elem.width(),
				scrollWidth = $elem.get(0).scrollWidth;
			// console.log(newScrollLeft);

			if (scrollWidth >= newScrollLeft - width) {
				$('.next-btn').removeClass('d-none');
				$('.prev-btn').removeClass('d-none');
				// console.log('center')
			}

			if (scrollWidth <= newScrollLeft + width) {
				$('.next-btn').addClass('d-none');
				$('.prev-btn').removeClass('d-none');
				// console.log('left')
			}
			if (newScrollLeft === 0) {
				$('.next-btn').removeClass('d-none');
				$('.prev-btn').addClass('d-none');
				// console.log('right')
			}

			// scrollLeftPrev = newScrollLeft;
		});
	}

	render() {
		var lastweek = this.props.lastweek;

		return (
			<div id="schedule" className="bg">
				<Helmet>
					<meta charSet="utf-8" />
					<title>ASL - Schedule</title>
					<meta
						name="description"
						content="AOV Star League, the most premium mobile Esports league hosted by Garena Indonesia, which is participated by EVOS, GGWP.ID, Rex Regum Qeon, Saudara Esports, Bigetron, Dunia Games, and Headhunters"
					/>
				</Helmet>
				<SectionTitle title="Schedule" />
				<div className="container schedule-wrapper detail">
					<BoxSchedule />
					<div className="middle-explanation">
						<a href="http://aov.co.id/youtubeaov" className="btn_click" target="_blank" rel="noopener noreferrer">
							<div className="buttons">WATCH NOW</div>
						</a>
						<p>
							Tonton langsung Live streaming dari <strong>YouTube Garena AOV</strong> dan{' '}
							<strong>Facebook Garena AOV</strong>{' '}
						</p>
					</div>

					{lastweek !== '' ? (
						<div className="last-table-wrapper">
							<h1 className="last-week-title">Last Week Match</h1>

							{lastweek &&
								lastweek.map((data, x) => (
									<div key={x} className="detail_schedule" style={{ marginTop: '2vw' }}>
										<div className="time desktop">
											<p>
												<span>{this.viewDay(data.date)}</span>{' '}
												{this.viewDate(data.date) + this.ordinal_suffix_of(data.date)}
											</p>
										</div>
										<div className="detail">
											{data.match
												? data.match.map((item, i) => (
														<div key={i} className="box_detail">
															<div className="time mobile">
																<p>
																	<span>{this.viewDay(data.date)}</span>{' '}
																	{this.viewDate(data.date) + this.ordinal_suffix_of(data.date)}{' '}
																	<span className="mobiletime">{this.viewTime(item.time)} WIB</span>
																</p>
															</div>
															<div className="team">
																<div
																	className="logo"
																	style={{
																		background: `url("${item.home_team?.team_logo}") no-repeat center center`,
																		backgroundSize: 'cover',
																	}}
																>
																	<img src={item.home_team?.team_logo} alt="" className="img-fluid" />
																</div>
																<div className="name">
																	{item.home_team?.team_name}
																	{/* {item.home_team.team_name} */}
																</div>
																<div className="score">{item.home_score}</div>
															</div>
															<div className="vs">
																<p>VS</p>
																<p>{this.viewTime(item.time)} WIB</p>
															</div>
															<div className="team away">
																<div className="score">{item.away_score}</div>
																<div className="name">
																	{item.away_team?.team_name}

																	{/* {item.away_team.team_name} */}
																</div>
																<div
																	className="logo"
																	style={{
																		background: `url("${item.away_team?.team_logo}") no-repeat center center`,
																		backgroundSize: 'cover',
																	}}
																>
																	<img src={item.away_team?.team_logo} alt="" className="img-fluid" />
																</div>
															</div>
														</div>
												  ))
												: ''}
										</div>
									</div>
								))}
						</div>
					) : (
						''
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	schedule: state.data.schedule,
	lastweek: state.data.lastweek,
	link_yt: state.data.link_yt,
});

export default connect(mapStateToProps)(Schedule);
