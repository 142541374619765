import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import ReactGA from 'react-ga4';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	swipe: true,
	autoplay: true,
};

class News extends React.Component {
	// getNewsbyLabel = (news,label) =>{
	// 	var data = '';

	// 	if(news){
	// 		if(label){
	// 			data = news.filter(function(item){
	// 					return item.news_label_id === label;
	// 				})
	// 		}else{
	// 			data = news;
	// 		}
	// 	}
	// 	return data;
	// }

	handleClick = (link, title) => {
		ReactGA.event({
			category: 'News Home',
			action: title,
		});

		if (link) {
			window.open(link, '_blank');
		}
	};

	sliceArray = (data) => {
		var news = data.slice(0, 5);
		return news;
	};

	// formatDate = (date) => {
	//     var month = ["01","02","03","04","05","06","07","08","09","10","11","12"];
	//     var new_date = new Date(date);
	//     var format = new_date.getDate() + '/' + (month[new_date.getMonth()]);
	//     return format;
	// }

	render() {
		var news = '';
		if (this.props.news) {
			news = this.props.news;
		}
		// var newstab = this.props.newstab;

		return (
			<div className={'sec4 ' + (news ? '' : 'd-none')}>
				<div className="news container d-none">
					<div className="head_title">
						<p>news</p>
						<Link to="/news" className="check" onClick={this.props.triggerClick}>
							See All {'>'}{' '}
						</Link>
					</div>
					<div className="box_news">
						<div className="box slider">
							<Slider {...settings}>
								{this.sliceArray(news)
									? this.sliceArray(news).map((data, x) => (
											<div key={x} onClick={() => this.handleClick(data.link, data.news_title)}>
												<img
													src={data.news_image}
													alt=""
													className="img-fluid"
													style={{
														background: 'url(' + data.news_image + ') no-repeat top center',
														backgroundSize: 'cover',
													}}
												/>
											</div>
									  ))
									: ''}
							</Slider>
						</div>
						<div className="box news_content">
							<div className="newsbylabel">
								{this.sliceArray(news)
									? this.sliceArray(news).map((item, j) => (
											<div key={j} onClick={() => this.handleClick(item.link, item.news_title)} className="newest">
												<div className="name">{item.news_title}</div>
												<div className="date">{item.news_date}</div>
											</div>
									  ))
									: ''}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	news: state.data.news,
	// newstab : state.data.newstab
});

export default connect(mapStateToProps)(News);
