// import { combineReducers } from "redux";
// import main from "./main";

// export default combineReducers({
// 	main
// });

import { combineReducers } from 'redux';

import data from './reducer';

const reducer = combineReducers({
  data
});

export default reducer;
