import React from "react";
import { connect } from "react-redux";

class Sponsor extends React.Component {
  render() {
    const sponsors = this.props?.assets?.sponsor;

    return (
      <div className="sponsor">
        <div className="list_sponsor">
          <span className="official_sponsor_text"></span>
          {sponsors?.length
            ? sponsors.map((sponsor, idx) => (
                <a
                  key={idx}
                  href={sponsor.link || null}
                  target={sponsor.link ? "_blank" : ""}
                >
                  <img src={sponsor.image} alt="" className="img-fluid" />
                </a>
              ))
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assets: state.data.assets,
});

export default connect(mapStateToProps)(Sponsor);
