import axios from "axios";

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
      return data;
    },
  ],
});

export const toggle_loader = (data) => ({
  type: "TOGGLE_LOADER",
  data: data,
});

export const put_data = (key, data) => ({
  type: "PUT_DATA",
  key,
  data,
});

export const login = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .get(process.env.REACT_APP_API_ENDPOINT)

      .then((resp) => {
        dispatch(put_data("login", resp.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const get_translate = () => {
  return (dispatch) => {
    cdn
      .get(`data.json?${Math.random() * 10000}`)
      .then((resp) => {
        const {
          teams,
          standings,
          schedule,
          last_match,
          news,
          videos,
          ...assets
        } = resp.data.data;
        dispatch({
          type: "FETCH_DATA_TEAMS",
          data: teams,
        });
        dispatch({
          type: "FETCH_DATA_STANDINGS",
          data: standings,
        });
        dispatch({
          type: "FETCH_DATA_SCHEDULE",
          data: schedule,
        });
        dispatch({
          type: "FETCH_DATA_LASTWEEK",
          data: last_match,
        });
        dispatch({
          type: "FETCH_DATA_NEWS",
          data: news,
        });
        dispatch({
          type: "FETCH_DATA_VIDEOS",
          data: videos,
        });
        dispatch({
          type: "FETCH_DATA_ASSETS",
          data: assets,
        });
      })
      .catch((err) => {});
  };
};
