import ReactGA from 'react-ga4';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SectionTitle from '../component/SectionTitle';

let actions = require('../../redux/actions');
const $ = window.$;
const AOS = window.AOS;

class Mechanism extends React.Component {
	componentDidMount() {
		ReactGA.send({ hitType: 'pageview', page: '/mechanism' });

		AOS.init({
			easing: 'ease-in-out-sine',
		});
		var lastPart = window.location.href.split('/');
		lastPart = lastPart[lastPart.length - 1];
		this.props.dispatch(actions.updateLastPart(lastPart));

		var banner = $('#mechanism .title').height();

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= banner) {
				$('.navbar').css('background', '#000');
			} else {
				$('.navbar').css('background', '#000');
			}
		});
	}

	render() {
		return (
			<div id="mechanism" className="bg">
				<Helmet>
					<meta charSet="utf-8" />
					<title>{this.props.assets.asl_header_title}</title>
					<meta
						name="description"
						content="AOV Star League, the most premium mobile Esports league hosted by Garena Indonesia, which is participated by EVOS, GGWP.ID, Rex Regum Qeon, Saudara Esports, Bigetron, Dunia Games, and Headhunters"
					/>
				</Helmet>
				<SectionTitle title={this.props.assets.asl_header_title} />
				<div className="content container detail" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="20">
					<div className="header"></div>

					<div className="padd">
						<div dangerouslySetInnerHTML={{ __html: this.props.assets.about_asl }}></div>
						<center>
							<img src={this.props.assets.prize} alt="" />
						</center>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	lastPart: state.data.lastPart,
	assets: state.data.assets,
});

export default connect(mapStateToProps)(Mechanism);
